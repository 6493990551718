import { useState, forwardRef, useImperativeHandle } from 'react';
import { Snackbar, Slide } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const SlideTransition = props => <Slide {...props} direction="up" />

export const Message = forwardRef(({ duration = 5000 }, ref) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('success')

    useImperativeHandle(
        ref,
        () => ({
            showSuccess(message='Success') {
                setMessage(message)
                setSeverity('success')
                setOpen(true)
            },
            showError(message='Error') {
                setMessage(message)
                setSeverity('error')
                setOpen(true)
            }
        }),
    )
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
        setTimeout(() => {
            setMessage('')
            setSeverity('success')
        }, 500)
    }
    return (
        <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} TransitionComponent={SlideTransition}>
            <MuiAlert className='w-full mt-2' elevation={10} variant="standard" onClose={handleClose} severity={severity}>{message}</MuiAlert>
        </Snackbar>
    )
})