import { useState, useContext, useRef } from "react";
import { Button, TextField } from '@material-ui/core';
import { DispatchContext } from "../reducers";
import { login, forgetPassword, IsValidEmail } from "../services";
import { Message } from '../components';
import { useHistory, useParams } from "react-router-dom";

export const Login = () => {
    const history = useHistory()
    const { path } = useParams()
    const dispatch = useContext(DispatchContext)
    const messageRef = useRef()
    const [forget, setForget] = useState(false)
    const [username, setUsername] = useState()


    const handleUsername = (event) => {
        setUsername(event.target.value)
    }
  

    const onLogin = async () => {
        dispatch({ type: 'spin on' })
        const auth = await login()
        

        if(path)
            history.push(`/${path}`) 
        if(auth !== true) {
            messageRef.current.showError('Login failed: You do not have access to this application. Please write to atcinnovations@aramcotrading.com for access.')
        }

        dispatch({ type: 'spin off' })
    }

    const onForget = async () => {
        setForget(true)
    }

    const onForgetPassword = async () => {
        if (!username) {
            messageRef.current.showError('Email is empty')
            return 
        } else if (!IsValidEmail(username)) {
            messageRef.current.showError('Incorrect email format')
            return 
        }
        const { error } = await forgetPassword(username)
        if (error) {
            messageRef.current.showError(error)
        } else {
            messageRef.current.showSuccess(`Request sent. Check your email to reset password`)
            setUsername(null)
            setForget(false)
        }
    }
    const onKeyPress= (event) => {
        if (event.code === 'Enter') {  
            onForgetPassword()
        }
    }

    const LoginButton = () => {
        return <Button className="white" variant='outlined' onClick={() => onLogin()}>Login</Button >;
    };

    const GuestButton = () => {
        return <div className='login__card__action__button'><Button variant='outlined' disabled={true} onClick={() => onLogin()}>Guest</Button ></div>;
    };

    return (
        <div style={{ 
                backgroundColor: '#464646',
                backgroundSize: 'cover',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
            }}>
            <Message ref={messageRef} />
            <div className='mt-3 f-12 bold-5 white w-full column-center'>
                <div>ATC ANALYTICS</div>
                <div className="f-01 mt-1 center">Vessel-Search Prototype</div>
            </div>
            <div className='login-button w-full column-center'>
                {!forget && <div className='column-center mb-2'>
                    <LoginButton />
                    <div className='mt-2'><Button variant='outlined' size='small' onClick={onForget}><div className='white'>Forget Password</div></Button ></div>
                </div>}
                {forget && <div className='column-center'>
                    <TextField variant='outlined' size='small' placeholder='Email' className='w-300' onChange={handleUsername} onKeyPress={onKeyPress} inputProps={{ style: { textTransform: "lowercase", color: 'white' } }}/>
                    <div className='mt-2 row-between w-200'>
                        <Button variant='outlined' size='small' onClick={() => setForget(false)}><div className='red'>Back</div></Button >
                        <Button variant='outlined' size='small' onClick={onForgetPassword}><div className='white'>Submit</div></Button >
                    </div>
                </div>}
            </div>
           
        </div>
    )
}