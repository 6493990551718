import { createContext } from "react";

export const initialState = {
    spin: false,
}

export const StateContext = createContext()
export const DispatchContext = createContext()

export const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case "spin on": return { ...state, spin: true }
        case "spin off": return { ...state, spin: false }
        default: return state
    }
}
  