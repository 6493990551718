import { useState, useEffect } from 'react';
import { Backdrop } from '@material-ui/core'; 
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },

   
}));

export const Loading = ({ spin = true }) => {
    const classes = useStyles();
    const [index, setIndex] = useState(0)
    const location = useLocation()
    let loc, tab;
    
    // useEffect(() => {
    //     loc = location.pathname.split('/')[1]
    //     tab = icons.find(i => loc === i.label.toLowerCase())
    //     if(tab)
    //         setIndex(icons.indexOf(tab))
    //     else
    //         setIndex(6)

    // }, [location])
    return spin && 
    <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress />
    </Backdrop>
}