import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Button } from '@material-ui/core'
import { list2, sleep } from '../services'
import { DispatchContext } from "../reducers";

export const Confirm = () => {
    const history = useHistory()
    const { code } = useParams()
    const dispatch = useContext(DispatchContext)
    const [confirmed, setConfirmed] = useState(false)

    useEffect(() => {
        async function fetch() {
            const { error } = await list2(`public/check`, code)
            if (error) {
                history.push('/')
            }
        }
        fetch()
    }, [])

    const onConfirm = async () => {
        dispatch({ type: 'spin on' })
        const { error } = await list2(`public/confirm`, code)
        dispatch({ type: 'spin off' })
        if (error) {
            await sleep(5000)
            history.push('/')
        } else {
            setConfirmed(true)
            await sleep(5000)
            history.push('/')
        }
    }

    return (
        <div style={{ 
            backgroundColor: '#f0f0f0',
            backgroundSize: 'cover',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
        }}>
            {!confirmed && <div className='welcolm-message column-center w-full f-04 bold'>
                <div className=''>Confirm to reset password?</div>
                <div className='mt-2'><Button variant='text' size='small' onClick={onConfirm}><div className='dark_blue'>Confirm</div></Button ></div>
            </div>}
            {confirmed && <div className='welcolm-message column-center w-full f-04 bold'>
                <div className=''>Your password has been reset</div>
                <div className=''>Check your email for the new password</div>
            </div>}
        </div>
    )
}