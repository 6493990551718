import React, {useEffect, useState, useContext} from 'react'
import Button from '@material-ui/core/Button'
import { post2, logout } from '../services'
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DispatchContext } from "../reducers";
import moment from 'moment'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import * as XLSX from 'xlsx';
import { list2 } from '../services';

import { useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: '1rem',
    overflowX: 'hide',
  },
  heading: {
   fontWeight: 'bold'
  },
 
  table: {
    minWidth: 340,
    borderRadius: '5px'
  },
  searchResults: {
    '& .MuiPaper-rounded': {
      borderRadius: '10px',
      border: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  searchFilters: {
    '& .MuiCardContent-root': {
      minWidth: '120px', 
      height: '57px'
    }
  },
  addTermBtn: {
    marginLeft: '1rem',
    '& .MuiButton-contained': { 
      backgroundColor: 'green',
      color: 'white',
    },
    '& .Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.26)',
      color: 'white'
    }
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
    border: '1px solid rgba(224, 224, 224, 1)'
  },

  tableHeaderCell: {
    paddingRight: 4,
    paddingLeft: 5,
    border: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: 'bold'
  }
}));

function createData(imo, vesselName, ownerName, riskLevel, vesselType, dwt, cubicCapacity, flag, yearBuilt, lastCargo, lastDeclaredDestination, lastDeclaredETA, availableAt) {
  return { imo, vesselName, ownerName, riskLevel, vesselType, dwt, cubicCapacity, flag, yearBuilt, lastCargo, lastDeclaredDestination, lastDeclaredETA, availableAt};
}


function Landing() {

    const [results, setResults] = useState([])

    const [rows, setRows] = useState([])
    const [wordList, setWordList] = useState([])
    const [inputText, setInputText] = useState('');
    const [lastUpdated, setLastUpdated] = useState('')
    const dispatch = useContext(DispatchContext)
    const classes = useStyles();
    useEffect(() => {
       setRows(results.map(vessel => 
        createData(vessel['Vessel_IMO'], vessel['Vessel_Name'], vessel['Owner_Name'], vessel['Risk_Level'], vessel['Vessel_Type'], vessel['DWT'], vessel['Cubic_Capacity'], vessel['Flag'], vessel['Year_Built'], vessel['Last_Cargo'], vessel['Last_Declared_Destination'], vessel['Last_Declared_ETA'], vessel['Available_At'])))
    }, [results])

  //   useEffect(() => {
  //     async function fetch() {
  //         const auth = await list2('auth')
  //         if(auth !== true) {
  //           await logout()
  //       }
          
  //     }
  //     fetch()
  // }, [])

    const onSearch = async () => {  
        try{
          dispatch({ type: 'spin on' })
          const { data } = await post2('vessel_risk/find_risk', {vessels: wordList})
          setResults(data)
          dispatch({ type: 'spin off' })

          // set the last updated to the latest evaluated_at time returned from the API
          setLastUpdated(data.reduce((acc, vessel) => {
            return acc > vessel['Evaluated_At'] ? acc : vessel['Evaluated_At'];
          }, ''))
        } catch (e) {
          dispatch({ type: 'spin off' })
            console.log(e)
        }
       
    }

    const onClearAll = () => {
      setWordList([]);
    }

    const removeWord = (index) => {
      // Remove the word card at the specified index
      const updatedWordList = [...wordList];
      updatedWordList.splice(index, 1);
      setWordList(updatedWordList);
    };

    const onAddTerm = () => {
     
        // Create a new word card
        setWordList([...wordList, inputText.trim()]);
        setInputText('');
    

     
    };

    const onLogout = () => {
       return logout()

    }

    const exportToExcel = (jsonData, fileName, highAndSanc=false) => {
      const all_data = jsonData.map((row) => {
        return {
          IMO: row.imo,
          'Vessel Name': row.vesselName,
          'Owner Name': row.ownerName,
          'Risk Level': row.riskLevel,
          'Vessel Type': row.vesselType,
          DWT: row.dwt,
          'Cubic Capacity': row.cubicCapacity,
          Flag: row.flag,
          'Year Built': row.yearBuilt,
          'Last Cargo': row.lastCargo,
          'Last Declared Destination': row.lastDeclaredDestination,
          'Last Declared ETA': row.lastDeclaredETA,
          'Available At': row.availableAt || 'N/A'
        };
      });

      const final_data = highAndSanc ? all_data.filter(row => row.riskLevel === 'high' || row.riskLevel === 'sanctioned') : all_data;
      const ws = XLSX.utils.json_to_sheet(final_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vessels');
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    const handleExportClick = (highAndSanc=false) => {
      // if (sanctioned) {
      //   exportToExcel(rows, 'high_and_sanctioned_vessels', true);
      //   return;
      // }
      exportToExcel(rows, 'vessels');
    };


  return (
    <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexDirection: 'column',
    }}> 
    <div className="mt-3 mr-3"><Button onClick={onLogout} variant='outlined' color='secondary'> Logout </Button></div>
       
        <div className="mt-3 w-full column-left">
        <div className="mx-3 mb-3 w-90p"> 
        <Accordion elevation={3}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Windward Risk Ratings</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer elevation={2} component={Paper}>
          <Table>
           
          <TableBody>
          <TableRow>
            <TableCell className={classes.heading}>Low</TableCell>
            <TableCell>A vessel with a LOW RISK rating implies there is no risk associated with this vessel when it comes to being involved in sanctions evading practices.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.heading}>Medium</TableCell>
            <TableCell>A vessel with a MEDIUM RISK rating means there is a medium risk this vessel is engaged in sanctions evading practices. 
              In this case, one or more of the building blocks behind this rating is most likely Amber. 
              Amber building block examples can include: STS activity in locations and with vessel types marked as suspicious, with longer duration OR STS activity 
              with a vessel that performed other suspicious activities directly before this STS activity.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.heading}>High</TableCell>
            <TableCell>A vessel with a HIGH RISK rating means there is a high risk this vessel is engaged in sanctions evading practices. 
              In this case, one or more of the building blocks behind this rating is most likely Pink. 
              An example of such a building block can include: Port call in sanctioned country.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.heading}>Sanctioned</TableCell>
            <TableCell>A vessel with a SANCTIONED rating means the vessel is included on a sanctions list or program including but not limited to OFAC (US), OFSI (UK), EU & the UN.</TableCell>
          </TableRow>
          </TableBody>
        
          </Table>
        </TableContainer>
        </AccordionDetails>
      </Accordion>
        
      </div>
            <div className="mx-3 w-90p row-between">
                <TextField onChange={(e) => setInputText(e.target.value)} value={inputText} fullWidth variant='outlined' type='number' placeholder='IMO Number'>
               
                </TextField>
                <div className={classes.addTermBtn} ><Button style={{minWidth: '150px'}} variant='contained' onClick={onAddTerm} disabled={!inputText.length}> <AddIcon /> Vessel </Button></div>
               <div className="ml-3"><Button variant='contained' color='primary' onClick={onSearch} disabled={!wordList.length}> Search </Button></div>
            </div>
          
            <div className="ml-3 w-90p row-left">
                    {!!wordList.length && wordList.map((word, index) => (
                      
                      <Card key={index} variant="outlined" elevation={5} className={classes.searchFilters} style={{ marginTop: '8px', marginLeft: index === 0 ? '0' : '8px', display: 'inline-block', position: 'relative'}}>
                             <IconButton
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={() => removeWord(index)}
              size="small"
            >
              <CloseIcon color="secondary"  />
              </IconButton>
                        <CardContent>{word}</CardContent>
                      </Card>
                      
                    ))}
                    {
                      !!wordList.length && <Button style={{marginLeft: '8px', marginTop: '8px'}} variant='contained' color='secondary' onClick={onClearAll} disabled={!wordList.length}> Clear All <DeleteOutlineIcon/> </Button>
                    }
                    
                </div>
            <div className="mt-3 ml-3 w-90p">
              {
                !rows.length && <div className='center'> Please enter a valid vessel imo number. </div>
              }
{
  !!rows.length && <div className={classes.searchResults}>
    <div className="row-between w-full">
   
     <div class="mr-3">
        <Button color="primary" variant="contained" onClick={handleExportClick}>Export Table to Excel <GetAppIcon /></Button>

        
      </div>
      
      <div class="mr-3">
      <Typography variant="caption" color="textSecondary" display="block" gutterBottom> Last Updated At: {`${moment(lastUpdated).utc().format('YYYY-MM-DD')} ${moment(lastUpdated).utc().format('HH:mm')} HRS (GMT)`}</Typography>
      </div>
    </div>
    <TableContainer elevation={2} component={Paper} className={classes.root}>
   
  <Table className={classes.table} aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableHeaderCell} align="center"> IMO </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Vessel Name </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Owner Name </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Risk Level </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Vessel Type </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> DWT </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Cubic Capacity </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Flag </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Year Built </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Last Cargo </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Last Declared Destination </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Last Declared ETA </TableCell>
        <TableCell className={classes.tableHeaderCell} align="center"> Available At </TableCell>

      </TableRow>
    </TableHead>
    <TableBody>
    {rows.map((row) => (
<TableRow key={row.imo}>
<TableCell className={classes.tableCell} component="th" scope="row">
  {row.imo}
</TableCell>
<TableCell className={classes.tableCell} >{row.vesselName}</TableCell>
<TableCell className={classes.tableCell} >{row.ownerName}</TableCell>
<TableCell className={classes.tableCell} >{row.riskLevel}</TableCell>
<TableCell className={classes.tableCell} >{row.vesselType}</TableCell>
<TableCell className={classes.tableCell} >{row.dwt}</TableCell>
<TableCell className={classes.tableCell} >{row.cubicCapacity}</TableCell>
<TableCell className={classes.tableCell} >{row.flag}</TableCell>
<TableCell className={classes.tableCell} >{row.yearBuilt}</TableCell>
<TableCell className={classes.tableCell} >{row.lastCargo}</TableCell>
<TableCell className={classes.tableCell} >{row.lastDeclaredDestination}</TableCell>
<TableCell className={classes.tableCell} >{!!row.lastDeclaredETA && (`${moment(row.lastDeclaredETA).utc().format('YYYY-MM-DD')} ${moment(row.lastDeclaredETA).utc().format('HH:mm')} HRS (GMT)`)}</TableCell>
<TableCell className={classes.tableCell} >{!!row.availableAt && (`${moment(row.availableAt).utc().format('YYYY-MM-DD')} ${moment(row.availableAt).utc().format('HH:mm')} HRS (GMT)`)}</TableCell>
</TableRow>
))}
    </TableBody>
  </Table>
</TableContainer></div>
}
              
            </div>
        </div>
       
    </div>
  )
}

export default Landing