import { Login, Landing, Confirm } from './pages';
import { useState, useReducer } from "react";
import { Loading } from './components'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import Cookies from 'js-cookie'
import { StateContext, DispatchContext, initialState, reducer } from './reducers'
import './styles/index.scss';

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [authed] = useState(Cookies.get('token') ? true : false)

  

    if (!authed) return (
      <HttpsRedirect>
          <StateContext.Provider value={state} >
              <DispatchContext.Provider value={dispatch} >
                  <Router>
                      <Loading spin={state.spin}/>
                      <Switch>
                          <Route exact path="/">
                              <Login />
                          </Route>
                          <Route exact path="/:path">
                              <Login />
                          </Route>
                          <Route path="/confirm/:code">
                              <Confirm />
                          </Route>
                          <Redirect to="/" />
                      </Switch>
                  </Router>
              </DispatchContext.Provider>
          </StateContext.Provider>
      </HttpsRedirect>
  )
  return (
          <HttpsRedirect>
          <StateContext.Provider value={state} >
              <DispatchContext.Provider value={dispatch} >
                  <Router>
        
                  <Loading spin={state.spin}/>
                          <Switch>
                              <Route exact path="/">
                                  <Landing />
                              </Route>
                              
                              <Redirect to="/" />
                          </Switch>
                    
                  </Router>
              </DispatchContext.Provider>
          </StateContext.Provider>
      </HttpsRedirect>
  )
}

export default App;
